import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Users',
    url: '/users',
    icon: 'cil-user',
  },
  {
    name: 'Collections',
    url: '/collections',
    icon: 'cil-factory',
  },
  {
    name: 'Items',
    url: '/items',
    icon: 'cil-room',
  },
  {
    name: 'Categories',
    url: '/categories',
    icon: 'cil-storage',
  },
  {
    name: 'Activities',
    url: '/activities',
    icon: 'cil-chart',
  },
  {
    name: 'Offers',
    url: '/offers',
    icon: 'cil-cart',
  },
  // {
  //   name: 'Settings',
  //   url: '/settings',
  //   icon: 'cil-settings',
  // }
];
